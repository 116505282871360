
import WithAppBar from "@/layouts/WithAppBar.vue";
import Vue from "vue";
import axios from "axios";
import TextFieldDatePicker from "@/components/text-field-date-picker.vue";
import PerformanceDashboardTable from "@/components/PerformanceDashboardTable/PerformanceDashboardTable.vue";
import PerformanceDashboardSelector, {
  SelectorItem
} from "@/components/PerformanceDashboardTable/PerformanceDashboardSelector.vue";
import B2bChart from "@/components/B2bChart/B2bChart.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { TableRow } from "@/models/performance-dashboard-summary.model";
import ChartDto from "@/models/performance-dashboard-chart.model";
import { AuthorizationService } from "@/services/authorization.service";
import { Practice } from "@/models/practice.model";
import PerformanceDashboardSelectorData from "@/models/performance-dashboard-params.model";
import { convertToMonthYear } from "@/utils/dateUtils.function";
import performanceDashboardParamsService from "@/services/performanceDashboardParams.service";
import performanceDashboardSummaryService from "../services/performanceDashboardSummary.service";
import performanceDashboardChartService from "../services/performanceDashboardChart.service";

type ChartData = ChartDto | null;
type SummaryTableData = Array<TableRow> | null;

export default Vue.extend({
  name: "PerformanceDashboard",
  components: {
    LoadingSpinner,
    TextFieldDatePicker,
    WithAppBar,
    PerformanceDashboardSelector,
    PerformanceDashboardTable,
    B2bChart
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      practices: [] as Array<Practice>,
      selectedPractice: 0,
      lastSelectedPractice: 0,
      showSummarySection: false,
      summaryTable: null as SummaryTableData,
      showChartSection: false,
      chartData: null as ChartData,
      selectorData: [] as Array<PerformanceDashboardSelectorData>,
      selectedGroup: "",
      selectedMetric: { text: "", value: "", groupName: "" } as SelectorItem
    };
  },
  computed: {
    summaryTableReady(): boolean {
      return !!this.summaryTable && this.selectorData.length > 0;
    },
    allFieldsSelected(): boolean {
      return this.selectedPractice !== 0 && this.startDate !== "" && this.endDate !== "";
    },
    dateRangeCorrect(): boolean {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      return startDate.getTime() <= endDate.getTime();
    },
    chartReady(): boolean {
      return !!this.chartData;
    },
    chartTitle(): string {
      return `${this.selectedMetric.groupName} - ${this.selectedMetric.text}`;
    }
  },
  methods: {
    updateStartDate(value: string) {
      this.startDate = value;
    },
    updateEndDate(value: string) {
      this.endDate = value;
    },
    practiceSelectItems() {
      const practices = this.practices.map((practice: Practice) => {
        return {
          text: practice.name,
          value: practice.practiceId
        };
      });

      if (this.practices.length === 1) {
        this.selectedPractice = this.practices[0].practiceId;
      }
      return practices;
    },
    async loadPractices() {
      const authService = new AuthorizationService();
      authService.getProfile().then(resp => {
        const id = resp.data.resp.practice_group_id;

        axios.get(`/api/vets/v1/practice-groups/${id}/practices/`).then(practiceResp => {
          this.practices = practiceResp.data.resp.map((obj: any) => new Practice({}, obj));
        });
      });
    },
    async loadSelector() {
      this.selectorData = await performanceDashboardParamsService.getParams();
    },
    async loadSummaryTable() {
      this.showSummarySection = true;
      const summaryData = await performanceDashboardSummaryService.getPerformanceDashboardSummary({
        practiceId: this.selectedPractice,
        startDate: this.startDate,
        endDate: this.endDate
      });
      this.summaryTable = Object.values(summaryData);
    },
    async loadChartData() {
      this.showChartSection = true;
      this.chartData = await performanceDashboardChartService.getChartData({
        practiceId: this.selectedPractice,
        startDate: this.startDate,
        endDate: this.endDate,
        metric: this.selectedMetric.value
      });
      this.chartData.labels = this.chartData.labels.map(label =>
        convertToMonthYear(new Date(label))
      );
    },
    chartSelectorChanged(selectedItem: SelectorItem) {
      this.chartData = null;
      this.selectedMetric = selectedItem;
      this.loadChartData();
    },
    runReport() {
      this.resetView();
      this.loadSummaryTable();
    },
    changePractice() {
      if (this.lastSelectedPractice !== this.selectedPractice) {
        this.resetView();
      }
    },
    resetView() {
      this.showSummarySection = false;
      this.showChartSection = false;
      this.summaryTable = null;
      this.chartData = null;
    }
  },
  mounted() {
    this.loadPractices();
    this.loadSelector();
  }
});
