
import Vue, { PropType } from "vue";
import PerformanceDashboardSelectorData from "@/models/performance-dashboard-params.model";

export interface SelectorItem {
  text: string;
  value: string;
  groupName: string;
}

export default Vue.extend({
  name: "PerformanceDashboardSelector",
  props: {
    items: Array as PropType<PerformanceDashboardSelectorData[]>,
    title: String,
    label: String
  },
  data() {
    return {
      selectorItem: {} as SelectorItem
    };
  },
  methods: {
    onChange() {
      this.$emit("changed", this.selectorItem);
      // Focus is not blurred automatically in current v-slot:selection
      const selector = this.$refs.selector as HTMLElement;
      selector.blur();
    }
  }
});
