export interface ChartDtoValues {
  [id: string]: Array<number>;
}

/**
 * Stores chart data received from any external source (service/api/db...).
 */
export default class ChartDto {
  labels: Array<string>;
  values: ChartDtoValues;

  constructor(init?: Partial<ChartDto>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      Object.assign(this, {
        labels: rawData.labels,
        values: rawData.values
      });
    }
  }
}

/**
 * Represents single data entity required for stacked bar chart.
 */
export class ChartDataset {
  label: string;
  data: Array<number>;
  backgroundColor: string;

  constructor(init: Partial<ChartDataset>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      this.label = rawData.label;
      this.data = rawData.data;
      this.backgroundColor = rawData.backgroundColor;
    }
  }
}

/**
 * Input parameters required for generating stacked bar chart.
 */
export interface ChartTemplateInput {
  labels: Array<string>;
  datasets: Array<ChartDataset>;
}

export enum BarColor {
  BLUE = "#4285F4",
  RED = "#EA4335",
  ORANGE = "#FBBC04",
  LIGHT_GREEN = "#52d452",
  GREEN = "#12721b",
  DARK_BLUE = "#2235e0",
  PURPLE = "#6a00ff",
  PINK = "#ca42f5",
  GRAY = "#747474"
}
