import axios from "axios";
import PerformanceDashboardSelectorData from "@/models/performance-dashboard-params.model";

export class PerformanceDashboardParamsService {
  private readonly baseUrl = "/api/vets/v1/practice/performance-dashboard/params";

  private buildValues(values: any, groupName: any): Array<PerformanceDashboardSelectorData> {
    return Object.keys(values).map(key => {
      return new PerformanceDashboardSelectorData({
        text: values[key],
        value: key,
        groupName: groupName
      });
    });
  }

  private mapToSelectorItems(rawData: any): Array<PerformanceDashboardSelectorData> {
    // to keep correct order
    const groups = ["Active Pets", "Signups", "Pending Activations", "Cancellations"];
    return groups.flatMap(group => {
      const headerItem: PerformanceDashboardSelectorData = new PerformanceDashboardSelectorData({
        header: group,
        divider: true
      });
      const items = this.buildValues(rawData[group], group);
      return [headerItem, ...items];
    });
  }

  public getParams(): Promise<Array<PerformanceDashboardSelectorData>> {
    return axios.get(this.baseUrl).then(response => {
      return this.mapToSelectorItems(response.data.resp);
    });
  }
}

const performanceDashboardParamsService = new PerformanceDashboardParamsService();
export default performanceDashboardParamsService;
