export default class PerformanceDashboardSelectorData {
  text?: string;
  value?: string;
  groupName?: string;
  // these names are required for building v-selector header
  header?: string;
  divider?: boolean;

  constructor(init?: Partial<PerformanceDashboardSelectorData>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      this.text = rawData.text;
      this.value = rawData.value;
      this.groupName = rawData.groupName;
      this.header = rawData.header;
      this.divider = rawData.divider;
    }
  }
}
