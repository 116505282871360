import axios from "axios";
import PerformanceDashboardSummary from "@/models/performance-dashboard-summary.model";

export interface PerformanceDashboardSummaryParams {
  practiceId: number;
  startDate: string;
  endDate: string;
}

export class PerformanceDashboardSummaryService {
  private readonly baseUrl = "/api/vets/v1/practice/performance-dashboard/summary";

  public getPerformanceDashboardSummary(
    payload: PerformanceDashboardSummaryParams
  ): Promise<PerformanceDashboardSummary> {
    return axios
      .get(this.baseUrl, {
        params: {
          practice_id: payload.practiceId,
          start_date: payload.startDate,
          end_date: payload.endDate
        }
      })
      .then(response => {
        return new PerformanceDashboardSummary({}, response.data.resp);
      });
  }
}

const performanceDashboardSummaryService = new PerformanceDashboardSummaryService();
export default performanceDashboardSummaryService;
