export interface TableRow {
  label: string;
  value?: number | string;
}

export default class PerformanceDashboardSummary {
  signups?: TableRow;
  activePets?: TableRow;
  pendingActivations?: TableRow;
  cancellations?: TableRow;
  renumerationForPeriod?: TableRow;

  constructor(init?: Partial<PerformanceDashboardSummary>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      // We could use received key value pair directly if we don't care about order and label name!
      Object.assign(this, {
        activePets: { label: "Active Pets", value: rawData["Active Pets"] },
        signups: { label: "Signups", value: rawData.Signups },
        pendingActivations: { label: "Pending Activations", value: rawData["Pending Activations"] },
        cancellations: { label: "Cancellations", value: rawData.Cancellations },
        renumerationForPeriod: {
          label: "Remuneration for period",
          value: rawData["Remuneration for period"]
        }
      });
    }
  }
}
