import ChartDto, {
  BarColor,
  ChartDataset,
  ChartDtoValues,
  ChartTemplateInput
} from "@/models/performance-dashboard-chart.model";

const defaultColors = Object.values(BarColor);

const createDataSets = (
  chartValues: ChartDtoValues,
  colors: Array<string>
): Array<ChartDataset> => {
  return Object.keys(chartValues).map((key, i) => {
    return new ChartDataset({
      label: key,
      data: chartValues[key],
      backgroundColor: colors[i % colors.length]
    });
  });
};

const createChartTemplate = (header: string, data: ChartTemplateInput): any => {
  const chartTitle = {
    display: true,
    text: header,
    align: "start",
    padding: {
      top: 10,
      bottom: 10
    },
    font: {
      size: 24
    },
    color: "#505D68"
  };

  // left align workaround - adding empty labels at the end
  const nonResponsiveBarsLimit = 6;
  if (data.labels.length < nonResponsiveBarsLimit) {
    const labelsOffset = nonResponsiveBarsLimit - data.labels.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < labelsOffset; i++) {
      data.labels.push("");
    }
  }

  return {
    type: "bar",
    data: data,
    options: {
      maxBarThickness: 100,
      plugins: {
        title: chartTitle
      },
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true
        }
      }
    }
  };
};

export const buildChartData = (
  header: string,
  inputData: ChartDto,
  colors?: Array<string>
): any => {
  const labels = inputData.labels;
  const values = inputData.values;
  const datasets = createDataSets(values, colors || defaultColors);
  return createChartTemplate(header, { labels, datasets });
};
