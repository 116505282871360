import axios from "axios";
import ChartDto from "@/models/performance-dashboard-chart.model";

export interface ChartRequestParams {
  practiceId: number;
  startDate: string;
  endDate: string;
  metric: string;
}

export class PerformanceDashboardChartService {
  private readonly baseUrl = "/api/vets/v1/practice/performance-dashboard/chart";

  public getChartData(payload: ChartRequestParams): Promise<ChartDto> {
    return axios
      .get(this.baseUrl, {
        params: {
          practice_id: payload.practiceId,
          start_date: payload.startDate,
          end_date: payload.endDate,
          metric: payload.metric
        }
      })
      .then(response => {
        return new ChartDto({}, response.data.resp);
      });
  }
}

const performanceDashboardChartService = new PerformanceDashboardChartService();
export default performanceDashboardChartService;
