
import Vue, { PropType } from "vue";
import { TableRow } from "@/models/performance-dashboard-summary.model";

export default Vue.extend({
  name: "performanceDashboardTable",
  props: {
    title: String,
    tableRows: Array as PropType<TableRow[]>
  }
});
