
import Vue, { PropType } from "vue";
import Chart from "chart.js/auto";
import { buildChartData } from "@/components/B2bChart/B2bChart.config";
import ChartDto from "@/models/performance-dashboard-chart.model";

export default Vue.extend({
  name: "b2b-chart",
  props: {
    inputData: ChartDto,
    colors: Array as PropType<string[]>,
    title: String
  },
  methods: {
    initChart() {
      if (!this.inputData) {
        return;
      }
      const ctx = document.querySelector("#b2b-chart");
      const chartData = buildChartData(this.title, this.inputData, this.colors);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const chart = new Chart(ctx, chartData);
    }
  },
  mounted() {
    this.initChart();
  }
});
