<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateString"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :cy-data="cyData"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" :type="pickerType" no-title scrollable :max="getEndDate">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="updateDate(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import Vue from "vue";
import { convertToMonthYear } from "@/utils/dateUtils.function";

export default Vue.extend({
  name: "text-field-date-picker",
  props: {
    label: String,
    pickerType: {
      type: String,
      default: "date"
    },
    cyData: String
  },
  data() {
    return {
      date: this.setInitialDate(),
      menu: false
    };
  },
  computed: {
    dateString() {
      const date = new Date(this.date);

      if (this.pickerType === "date") return date.toISOString().substr(0, 10);

      return convertToMonthYear(date);
    },
    getEndDate() {
      const date = new Date();
      // last day of the month
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return endDate.toISOString().slice(0, 10);
    }
  },
  methods: {
    updateDate(date) {
      this.$refs.menu.save(date);
      this.$emit("update", date);
    },
    setInitialDate() {
      if (this.pickerType === "date") return new Date().toISOString().substr(0, 10);
      return new Date().toISOString().substr(0, 7);
    }
  },
  mounted() {
    this.$emit("update", this.date);
  }
});
</script>

<style scoped></style>
